
import { IonItem, IonIcon, IonLabel } from "@ionic/vue";
import { defineComponent } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "MenuItemComponent",
  components: {
    IonItem,
    IonIcon,
    IonLabel,
  },
  props: ["link", "icon", "label", "disabled"],

  setup() {
    const route = useRoute();

    return {
      route,
    };
  },
});
