import * as Realm from "realm-web";

const realmApp = new Realm.App({ id: 'application-0-mbwjh' });
let realmUser: any = null;

async function logIn(email: string, password: string) {
  const credentials = Realm.Credentials.emailPassword(email, password);
  const _realmUser = await realmApp.logIn(credentials);
  realmUser = _realmUser;  

  return realmUser;
}

async function completion(prompt: string) {
  if (realmUser.id) {
    const completion = await realmUser.functions.getAiCompletion(prompt, "es-MX");
    return completion;
  }

  return null;
}

function isAuthenticated() {
  return realmUser?.id;
}

export default {
  logIn,
  getUser: () => realmUser,
  completion,
  isAuthenticated
}