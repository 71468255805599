import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    class: _normalizeClass(["hydrated", { selected: _ctx.route.path === _ctx.link }]),
    "router-direction": "root",
    "router-link": _ctx.link,
    lines: "none",
    detail: false,
    disabled: _ctx.disabled || false
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_icon, {
        slot: "start",
        ios: _ctx.icon,
        md: _ctx.icon
      }, null, 8, ["ios", "md"]),
      _createVNode(_component_ion_label, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.label), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["router-link", "class", "disabled"]))
}