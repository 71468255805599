export default {
  "sideMenu": {
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prueba Alpha"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenido"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario"])},
    "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsletter"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escribir un correo"])},
    "developer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarrollado por JT Guzman"])},
    "flows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flujos para Comunicar"])}
  },
  "loginPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AIssertive"])},
    "form": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese su correo electrónico y contraseña"])},
      "emailLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
      "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese su correo electrónico"])},
      "passwordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
      "passwordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese su contraseña"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])}
    }
  },
  "welcomePage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AIssertive"])},
    "whatWeDo": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es hora de tomar el control de tus comunicaciones"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reduce estrés y logra objetivos."])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prueba esta aplicación y resuelve de una vez tus problemas de comunicación. AIssertive es una aplicación que te ayuda a comunicarte de manera asertiva y respetuosa con ayuda de Inteligencia Artificial."])}
    },
    "getStarted": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comience con nuestros Flujos"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creamos Flujos de Comunicación para guiarte a través de situaciones difíciles"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proporcionamos pautas asistidas por IA para comprender y estructurar sus pensamientos y expresarlos de una manera adecuada para lograr resultados."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitar Flujos"])}
    }
  },
  "flows": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flujos"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elija un flujo para comenzar"])},
    "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comenzar"])},
    "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proximamente"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anterior"])},
    "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear"])},
    "salaryRaise": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedir un Aumento de Sueldo"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LABORAL"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedir un aumento de sueldo es difícil. Inicia este flujo para lograrlo."])},
      "slides": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cómo pedir un aumento de sueldo"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los empleadores necesitan que sus trabajadores sean productivos y valiosos para la empresa. Si crees que eres valioso y has mejorado tu desempeño, entonces tienes que pedir un aumento de sueldo. En este flujo vas a: <ul><li>Expresar por qué eres valioso para la empresa.</li><li>Dar ejemplos de tus logros y,</li><li>Hacer una solicitud concreta de aumento.</li></ul><p>Ten confianza en tus capacidades y recuerda que un buen empleador quiere que seas feliz y productivo.</p>"])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Por qué quieres un aumento de sueldo?"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hables de razones personales sino de cómo aportas a la empresa, clientes o a tu equipo. Menciona tu compromiso con la empresa y tu deseo de seguir creciendo."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Que valor agregas a la empresa y a tu equipo?"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En primer lugar ..."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cuáles son tus logros profesionales?"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menciona los desafíos que has enfrentado y cómo los has superado. Menciona los proyectos en los que has trabajado y los resultados que has obtenido."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cuáles son tus logros?"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis logros son ..."])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿De cuánto es el aumento?"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasta un 3% se considera normal, de 4% a 5% es un buen aumento. Más del 5% es un aumento significativo y debes estar dispuesto a negociarlo con tu empleador."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿De cuánto es el aumento?"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona un porcentaje"])},
          "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aumento de 3%, Aumento de 4%, Aumento de 5%, Aumento de 10%, Aumento de 20% o más"])}
        },
        "4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consideraciones"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuerda siempre tomar en consideración con quién estás hablando. Estos son algunos consejos para que estés preparado en tu conversación.<ul><li>Agenda una reunión para hablar de este tema.</li><li>Averigua cuál es el sueldo de mercado de tu posición.</li><li>Enfócate en cómo el aumeto benefica a tu empleador.</li><li>Prepárate para un No. Si es por motivos de la empresa, entonces solicita claridad en qué debe ocurrir para lograr el aumento.</li></ul><p>Presiona el botón para crear tu mensaje.</p>"])}
        }
      }
    },
    "request": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informar un Cambio en las Condiciones"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LABORAL"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los proyectos y contratos pueden tener cambios. Inicia este flujo para informarlo de forma correcta e iniciar un diálogo."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comenzar"])}
    },
    "sayNo": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decir No"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PERSONAL Y LABORAL"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decir no es difícil pero es el primer paso para reducir estrés y lograr tus objetivos. Inicia este flujo para comunicar un no respetuoso y asertivo."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comenzar"])}
    }
  },
  "emailPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escribir un correo"])},
    "preparation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siga estas instrucciones"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divida el mensaje en situación, apreciación y solicitud"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siga estos pasos para crear un correo electrónico apropiado. Al llenar los campos, podrá crear un mensaje que será claro y asertivo."])}
    },
    "situation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1) Situación"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describa la situación lo más objetiva posible"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué sucedió? ¿Cuál es el problema? ¿Cuál es el contexto? ¿Cuáles son los hechos? ¿Cuáles son las consecuencias? ¿Cuáles son los intereses? ¿Cuáles son los supuestos?"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La situación es..."])}
    },
    "appreciation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2) Apreciación"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describa sus pensamientos y sentimientos sin pedir nada"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué piensa de la situación? ¿Qué siente de la situación? ¿Por qué hay un conflicto?"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yo pienso..."])}
    },
    "request": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3) Solicitud"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describa lo que desea lograr. Sea específico y claro."])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué quieres lograr? ¿Qué quieres cambiar? ¿Qué quieres hacer?"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yo quiero..."])}
    },
    "process": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesar mensaje"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El sistema generará una plantilla de correo electrónico para este propósito."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generar mensaje"])}
    },
    "result": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sugerencia de Aissertive"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilice esta plantilla para enviar su correo electrónico."])}
    }
  },
  "global": {
    "remaining": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Caracteres restantes: ", _interpolate(_named("count"))])}
  }
}