export default {
  "sideMenu": {
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alpha testing"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write an email"])},
    "developer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developed by JT Guzman"])},
    "flows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication Workflows"])}
  },
  "loginPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AIssertive"])},
    "form": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email and password"])},
      "emailLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email"])},
      "passwordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "passwordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your password"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log In"])}
    }
  },
  "welcomePage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Aissertive"])},
    "whatWeDo": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in control of your communication"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reduce stress and achieve goals"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In difficult situations of life, good communication is the only way to resolve conflicts.\n But it is in these moments where communicating is more difficult.\n Aissertive combines specialized techniques of effective communication and artificial intelligence to help you navigate tense moments whether at work or in your personal life."])}
    },
    "getStarted": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use our guidelines"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We provide guidelines assisted by AI to understand and structure your thoughts, so that you can express them in a proper way to achieve results."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write an email"])},
      "feelings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communicate a feeling (coming soon)"])}
    }
  },
  "flows": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flujos"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elija un flujo para comenzar"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anterior"])},
    "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear mensaje"])},
    "badNews": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comunicar una Mala Noticia"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PERSONAL Y LABORAL"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comunicar malas noticias es difícil. Inicia este flujo para crear un mensaje directo y respetuoso."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comenzar"])},
      "slides": {
        "one": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introducción"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La mejor forma de comunicar una mala noticia es ser <b>directo y claro</b>. Debemos anticiparnos a cómo va a reaccionar nuestra audiencia y prepararnos para responder a sus preguntas. Pero en ningún caso debemos ocultar la información o mentir. Muchas veces las malas noticias traen escondidas <i>oportunidades de crecimiento</i>. <br> Los pasos que seguiremos son: <ol><li>Describir la situación</li><li>Declarar la mala noticia</li><li>Explicar las consecuencias</li></ol>"])}
        },
        "two": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe la Situación"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En este paso cuenta qué ha ocurrido. Puedes decir cómo te sientes pero intenta ser objetivo. <b>Enfócate en los hechos</b>. Escribe qué sucedió y cuál es el contexto."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe libremente cuál es la situación. Se lo más objetivo posible."])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La situación es..."])}
        },
        "three": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declara la Mala Noticia"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En una sola frase declara la mala noticia."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escribe la mala noticia de la forma más clara y directa posible."])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La mala noticia es..."])}
        },
        "four": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explica las Consecuencias"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cuáles son las consecuencias de lo ocurrido? ¿Cómo esperas que se desenvuelva la situación? ¿Cómo debería comportarse tu audiencia?"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has una lista de los efectos que tiene esta mala noticia y tu expectativas."])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En consecuencia..."])}
        },
        "five": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesar Mensaje"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presiona el boton verde y nuestra IA creará un correo electrónico para ti. Puedes modificarlo y enviarlo a tu destinatario. Recuerda siempre leer el mensaje antes de enviarlo. Si no quedas satisfecho con el resultado puedes actualizar los campos y volver a procesar el mensaje."])}
        }
      }
    },
    "emailPage": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write an email"])},
      "preparation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow this instructions"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divide the message into situation, appreciation and request"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please follow this steps to create an appropiate email. By filling the fields you will be able to create a message that will be clear and assertive."])}
      },
      "situation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1) Situation"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe the situation as objective as possible"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What happened? What is the problem? What is the context? What are the facts? What are the consequences? What are the interests? What are the assumptions?"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The situation is..."])}
      },
      "appreciation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2) Appreciation"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe your thoughts and feelings without asking for anything"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What do you think about the situation? What do you feel about the situation? Why there is a confict?"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I think..."])}
      },
      "request": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3) Request"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe what you want to achieve. Be specific and clear."])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What do you want to achieve? What do you want to change? What do you want to do?"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want..."])}
      },
      "process": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process message"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The system will generate an email template for this purpose."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate message"])}
      },
      "result": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aissertive Suggestion"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use this template to send your email."])}
      }
    },
    "global": {
      "remaining": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Remaining characters: ", _interpolate(_named("count"))])}
    }
  }
}