import { createI18n } from 'vue-i18n'

import enUS from './locales/en-US.json';
import esMX from './locales/es-MX.json';


type MessageSchema = typeof enUS | typeof esMX;

export default createI18n<[MessageSchema], 'en-US' | 'es-MX'>({
  legacy: false,
  locale: process.env.VUE_APP_I18N_LOCALE || 'es-MX',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'es-MX',
  messages: {
    'en-US': enUS,
    'es-MX': esMX
  }
})
