
import {
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRouterOutlet,
  IonSplitPane,
  IonSegment,
  IonSegmentButton,
  IonToggle,
  IonItemDivider,
  IonFooter,
  IonText,
} from "@ionic/vue";
import { defineComponent, ref, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
import {
  mailOutline,
  telescopeOutline,
  arrowRedoOutline,
  cafeSharp,
  moonOutline,
  wineOutline,
  personCircleOutline,
  newspaperOutline,
  homeOutline,
} from "ionicons/icons";
import MenuItem from "./components/MenuItem.vue";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonRouterOutlet,
    IonSplitPane,
    IonSegment,
    IonSegmentButton,
    IonToggle,
    MenuItem,
    IonItemDivider,
    IonFooter,
    IonText,
  },
  setup() {
    const selectedIndex = ref(0);
    const route = useRoute();
    const app = getCurrentInstance();

    function changeLanguage(lang: string) {
      if (app) {
        app.appContext.config.globalProperties.$i18n.locale = lang;
      }
    }

    function toggleDarkMode(state: CustomEvent) {
      document.body.classList.toggle("dark", state.detail.checked);
    }

    return {
      route,
      mailOutline,
      selectedIndex,
      cafeSharp,
      moonOutline,
      telescopeOutline,
      arrowRedoOutline,
      wineOutline,
      personCircleOutline,
      newspaperOutline,
      homeOutline,
      toggleDarkMode,
      changeLanguage,
      isSelected: (url: string) => (url === route.path ? "selected" : ""),
    };
  },
});
