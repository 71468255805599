import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_MenuItem = _resolveComponent("MenuItem")!
  const _component_ion_menu_toggle = _resolveComponent("ion-menu-toggle")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_ion_menu = _resolveComponent("ion-menu")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_split_pane = _resolveComponent("ion-split-pane")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_split_pane, {
        "content-id": "main-content",
        when: "xl"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_menu, {
            "content-id": "main-content",
            type: "overlay"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_content, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_list, { id: "inbox-list" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_list_header, null, {
                        default: _withCtx(() => [
                          _createTextVNode("AIssertive")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_note, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("sideMenu.status")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_menu_toggle, { "auto-hide": false }, {
                        default: _withCtx(() => [
                          _createVNode(_component_MenuItem, {
                            link: "/welcome",
                            icon: _ctx.wineOutline,
                            label: _ctx.$t('sideMenu.welcome')
                          }, null, 8, ["icon", "label"]),
                          _createVNode(_component_MenuItem, {
                            link: "/home",
                            icon: _ctx.homeOutline,
                            label: _ctx.$t('sideMenu.home'),
                            disabled: true
                          }, null, 8, ["icon", "label"]),
                          _createVNode(_component_MenuItem, {
                            link: "/flows",
                            icon: _ctx.arrowRedoOutline,
                            label: _ctx.$t('sideMenu.flows')
                          }, null, 8, ["icon", "label"]),
                          _createVNode(_component_MenuItem, {
                            link: "/user",
                            icon: _ctx.personCircleOutline,
                            label: _ctx.$t('sideMenu.user'),
                            disabled: true
                          }, null, 8, ["icon", "label"]),
                          _createVNode(_component_MenuItem, {
                            link: "/billing",
                            icon: _ctx.newspaperOutline,
                            label: _ctx.$t('sideMenu.newsletter'),
                            disabled: true
                          }, null, 8, ["icon", "label"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_footer, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_segment, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_segment_button, {
                        value: "default",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeLanguage('es-MX')))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Español")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_segment_button, {
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeLanguage('en-US'))),
                        disabled: true
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode("English")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_list, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_toggle, {
                            slot: "end",
                            onIonChange: _ctx.toggleDarkMode
                          }, null, 8, ["onIonChange"]),
                          _createElementVNode("small", null, _toDisplayString(_ctx.$t("sideMenu.developer")), 1),
                          _createVNode(_component_ion_icon, {
                            slot: "end",
                            ios: _ctx.moonOutline,
                            md: _ctx.moonOutline
                          }, null, 8, ["ios", "md"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_router_outlet, { id: "main-content" })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}