import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import RealmApi from "../realm";


const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/welcome'
  },
  {
    path: '/welcome',
    component: () => import ('../views/WelcomePage.vue')
  },
  {
    path: '/email',
    component: () => import ('../views/EmailPage.vue')
  },
  {
    path: '/login',
    component: () => import ('../views/LoginPage.vue')
  },
  {
    path: '/flows',
    component: () => import ('../views/FlowsPage.vue')
  },
  {
    path: '/flows/salary-raise',
    component: () => import ('../views/flows/SalaryRaise.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/welcome', '/login'];
  const authRequired = !publicPages.includes(to.path);
  const auth = RealmApi.isAuthenticated();

  if (to.path === '/login' && auth) {
    return '/welcome';
  }

  if (authRequired && !auth) {
      return `/login?to=${to.path}`;
  }

});

export default router